window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
     console.log('heyflow submit:', event.detail);
});

document.getElementById('id-00e0a308').addEventListener('click', function() {
    // Your code here
    alert('Button with ID id-00e0a308 was clicked!');
});
